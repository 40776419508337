import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Link } from "gatsby"

const AnimalHealthPlans = () => (
  <Layout>
    <SEO
      title="Animal Health Plans"
      description="Unsure of what your animals require to be healthy and protected from disease? We can create a customised health plan for your lifestyle block animals"
    />
    <section>
      <div className="container">
        <h1 className="section-heading">Animal Health Plans</h1>
        <div className="row">
          <div className="col-lg-6">
            <p>
              The team at Lakeside Lifestyle can take the stress and guesswork
              out of ensuring your animals are getting the right treatments at
              the right time by creating a customisable animal health plan.
            </p>
            <p>
              These annual health plans are customised for your animal goals and
              your property, and ensure your animals are kept as healthy as
              possible with the correct treatments at the correct time. Benefits
              of an annual health plan include;
            </p>
            <ul>
              <li>
                Tailored treatments for individual animals or the whole
                flock/herd
              </li>
              <li>All treatments can be administered by our team</li>
              <li>We can help with yarding and restraint</li>
              <li>WE will remind YOU when the treatment needs to be done</li>
            </ul>
            <p className="mb-0">
              Plans can be created for sheep, cattle, pigs, goats, deer and
              camelids. <Link to="/contact/">Contact us</Link> today for a free
              quote.
            </p>
          </div>
          <div className="col-lg-6 mt-4 mt-lg-0 d-flex align-items-center">
            <StaticImage
              aspectRatio={16 / 10}
              src="../../images/farm-goats.jpg"
              alt="farm goats"
              loading="eager"
            />
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default AnimalHealthPlans
